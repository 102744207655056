import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import ButtonGradient from "./ButtonGradient";
import InputBox from "./InputBox";
import RoleCheckbox from "./RoleCheckbox";
// icons
import visual_icon from "../images/AiVisuals.png";
import voice_icon from "../images/AUDIO_avatar_standing.png";
import text_icon from "../images/Balerina_AI_Wzskazuje_palcem.png";
import medical_icon from "../images/MEDI_avatar.png";
import remote_icon from "../images/remote.png";
import { Trans, useTranslation } from "react-i18next";

export default function ContactForm() {
  const { t } = useTranslation();
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [roleInput, setRoleInput] = useState([""]);
  const [message, setMessage] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const [formIsValid, setFormIsValid] = useState(false);

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        nameInput.trim().length >= 2 &&
        validateEmail(emailInput) &&
        roleInput.length > 1 &&
        message.trim() &&
        acceptTerms
      ) {
        setFormIsValid(true);
      } else {
        setFormIsValid(false);
      }
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, [nameInput, emailInput, roleInput, message, acceptTerms]);

  const submitHandler = (e: Event) => {
    e.preventDefault();
    if (!formIsValid) return;
    console.log("sending form");

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6Lf_sIcgAAAAAGAIu-LmyqUH2-_ZXlsmTy6O2dis", {
          action: "submit",
        })
        .then((token: string) =>
          fetch(
            "https://europe-central2-weimpact-prod.cloudfunctions.net/contact",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token,
                data: {
                  name: nameInput,
                  email: emailInput,
                  role: roleInput.filter((d) => d && d.length > 0).join(","),
                  message,
                },
              }),
            }
          )
        )
        .then((res: Response) => res.json())
        .then(() => {
          setSuccess(true);
          setError(null);
          console.log("form has been sent");
        })
        .catch(() => {
          setSuccess(false);
          setError(t("The message could not be sent. Please try again."));
          console.log("form cannot be sent");
        });
    });

    // Clear inputs
    setNameInput("");
    setEmailInput("");
    setRoleInput([""]);
    setMessage("");
    setAcceptTerms(false);
  };

  return (
    <form
      action="#"
      onSubmit={(e: any) => submitHandler(e)}
      className="flex flex-col gap-5 max-w-lg mx-auto">
      <InputBox label={t("Name")}>
        <input
          onInput={(e: any) => setNameInput(e.target.value)}
          value={nameInput}
          type="text"
          name="name"
          className="block px-3 py-2 w-full border border-black rounded-md"
        />
      </InputBox>
      <InputBox label={t("Email")}>
        <input
          onInput={(e: any) => setEmailInput(e.target.value)}
          value={emailInput}
          type="text"
          name="email"
          className="block px-3 py-2 w-full border border-black rounded-md"
        />
      </InputBox>
      <InputBox label={t("You are interested in:")}>
        <div className="flex justify-around  border border-black rounded-md">
          <RoleCheckbox
            setRoleInput={setRoleInput}
            name="interestedIn"
            label={t("visual")}
            icon={visual_icon}
            selected={roleInput.includes(t("visual"))}
          />
          <RoleCheckbox
            setRoleInput={setRoleInput}
            name="interestedIn"
            label={t("voice")}
            icon={voice_icon}
            selected={roleInput.includes(t("voice"))}
          />
          <RoleCheckbox
            setRoleInput={setRoleInput}
            name="interestedIn"
            label={t("medical")}
            icon={medical_icon}
            selected={roleInput.includes(t("medical"))}
          />
          <RoleCheckbox
            setRoleInput={setRoleInput}
            name="interestedIn"
            label={t("text")}
            icon={text_icon}
            selected={roleInput.includes(t("text"))}
          />
          <RoleCheckbox
            setRoleInput={setRoleInput}
            name="interestedIn"
            label={t("remote")}
            icon={remote_icon}
            selected={roleInput.includes(t("remote"))}
          />
        </div>
      </InputBox>
      <InputBox label={t("Message")}>
        <textarea
          onInput={(e: any) => setMessage(e.target.value)}
          value={message}
          name="message"
          className="block px-3 py-2 w-full h-72 border border-black rounded-md resize-none"></textarea>
      </InputBox>
      <div className="text-left -mt-2">
        <input
          id="termsCheckbox"
          type="checkbox"
          checked={acceptTerms}
          onChange={() => setAcceptTerms((prev) => !prev)}
        />
        <label htmlFor="termsCheckbox" className="inline-block ml-3 font-bold">
          <Trans>I accept</Trans>{" "}
          <Link to="/terms-and-conditions" className="text-accent">
            <Trans>terms and condiitons</Trans>
          </Link>
        </label>
        {error && (
          <p className="text-red-600 text-base font-semibold">{error}</p>
        )}
        {success && (
          <p className="text-green-600 text-base font-semibold">
            {t("The message was sent successfully.")}
          </p>
        )}
      </div>
      <ButtonGradient
        disabled={formIsValid ? false : true}
        text={t("Submit")}
        type="submit"
        sitekey="6Lf_sIcgAAAAAGAIu-LmyqUH2-_ZXlsmTy6O2dis"
        aClasses="g-recaptcha w-min ml-auto mr-auto lg:ml-[unset]"
      />
    </form>
  );
}
