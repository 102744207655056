import React from "react";

export interface InputBoxProps {
  label: string;
  children: React.ReactNode;
}

export default function InputBox({ label, children }: InputBoxProps) {
  return (
    <div className="text-left">
      <h3 className="font-normal">{label}</h3>
      {children}
    </div>
  );
}
