import React from "react";

export interface RoleCheckboxProps {
  icon: string;
  label: string;
  name: string;
  setRoleInput: any;
  selected?: boolean;
}

export default function RoleCheckbox({
  icon,
  name,
  label,
  setRoleInput,
  selected,
}: RoleCheckboxProps) {
  return (
    <div className="w-1/5">
      <input
        onInput={(e: any) =>
          setRoleInput((prev: string[]) => {
            const value: string = e.target.value;
            if (prev.includes(value)) return prev.filter((p) => p != value);
            return [...prev, e.target.value];
          })
        }
        id={label}
        value={label}
        type="checkbox"
        name={name}
        className="peer absolute -z-full opacity-0 pointer-events-none"
      />
      <label
        htmlFor={label}
        className="peer-focus-visible:child:outline text-center">
        <figure
          className={`flex flex-col items-center w-full h-full py-2 transition-colors ${
            selected && "bg-accent bg-opacity-20"
          }`}>
          <img src={icon} alt="" className="block h-14 mt-2 mb-2 opacity-1" />
          <figcaption className="px-1">{label}</figcaption>
        </figure>
      </label>
    </div>
  );
}
